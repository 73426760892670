import { stateResetMutator, resetStateAction } from '@mixins/store-common-methods'
import { getAuthToken, deleteAuthToken, postForgotPassword, patchPassword } from '@api/auth'

const initialState = () => {
  return {
    token: window.localStorage.getItem('token') || '',
    role: window.localStorage.getItem('role') || '',
    full_name: window.localStorage.getItem('full_name') || '',
    username: window.localStorage.getItem('username') || ''
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    AUTH_SUCCESS (state, { token, role, name, username }) {
      state.token = token
      state.role = role
      state.full_name = name
      state.username = username
      window.localStorage.setItem('token', token)
      window.localStorage.setItem('role', role)
      window.localStorage.setItem('full_name', name)
      window.localStorage.setItem('username', username)
    },
    SET_NAME (state, { name }) {
      state.full_name = name
      window.localStorage.setItem('full_name', name)
    },
    AUTH_LOGOUT (state) {
      state.token = ''
      state.role = ''
      state.full_name = ''
      state.username = ''
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('role', '')
      window.localStorage.setItem('full_name', '')
      window.localStorage.setItem('username', '')
    },
    SET_TOKEN (state, token) {
      state.token = token
      window.localStorage.setItem('token', token)
    }
  },
  actions: {
    resetState: resetStateAction(),
    setName ({ commit }, { name }) {
      commit('SET_NAME', { name })
    },
    login ({ commit, dispatch }, user) {
      return new Promise(async (resolve, reject) => {
        // Fetch Auth Token and User
        let auth = await getAuthToken(user.username, user.password)
        if (auth) {
          commit('AUTH_SUCCESS', { token: auth.token, role: auth.role, name: auth.name, username: auth.username })
          resolve(auth)
        } else {
          reject(new Error('Failed to authenticate'))
        }
      })
    },
    logOut ({ commit }) {
      return new Promise(async (resolve, reject) => {
        let response = await deleteAuthToken()
        commit('AUTH_LOGOUT')
        if (response) {
          resolve(response)
        } else {
          reject(new Error('Failed to delete token'))
        }
      })
    },
    async resetPassword (_, email) {
      await postForgotPassword(email)
    },
    async partialUpdatePassword (_, password) {
      await patchPassword(password)
    },
    resetAuth ({ commit }) {
      commit('AUTH_LOGOUT')
    },
    setToken ({ commit }, { token }) {
      commit('SET_TOKEN', token)
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getToken: state => state.token,
    getUserRole: state => state.role,
    getUsersFullName: state => state.full_name,
    getUsername: state => state.username
  }
}
