import { httpClient } from '@api/client'

// Users Endpoints
const USERS_APP = '/users/'
const ROLES_ACTION = 'roles/'
const INFO_ACTION = 'info/'
const SET_PASSWORD_ACTION = 'set_password/'
const LANGUAGES = 'languages/'

export const getUsers = async (query = false) => {
  let userData = null
  try {
    if (query) {
      const { data } = await httpClient.get(USERS_APP + `?${query}`)
      userData = data
    } else {
      const { data } = await httpClient.get(USERS_APP)
      userData = data
    }
    return userData || null
  } catch (error) {
    return null
  }
}

export const getUser = async () => {
  try {
    const { data } = await httpClient.get(USERS_APP + INFO_ACTION)
    return data.data || []
  } catch (error) {
    return null
  }
}
export const postNewUserPassword = async (request) => {
  try {
    const { data } = await httpClient.post(USERS_APP + SET_PASSWORD_ACTION, request)
    return data.data || null
  } catch (error) {
    return error.response.data
  }
}

export const postUser = async (request) => {
  try {
    const { data } = await httpClient.post(USERS_APP, request)
    return data || null
  } catch (error) {
    return error.response.data
  }
}

export const patchUser = async (id, request) => {
  try {
    const { data } = await httpClient.patch(USERS_APP + id + '/', request)
    return data || null
  } catch (error) {
    return error.response.data
  }
}

export const deleteUser = async (id) => {
  try {
    await httpClient.delete(USERS_APP + id + '/')
    return true
  } catch (error) {
    return false
  }
}

export const getUserRoles = async () => {
  try {
    const { data } = await httpClient.get(USERS_APP + ROLES_ACTION)
    return data
  } catch (error) {
    return null
  }
}

export const getUserLanguages = async () => {
  try {
    const { data } = await httpClient.get(USERS_APP + LANGUAGES)
    return data
  } catch (error) {
    return null
  }
}

export const postIsUniqueEmailOrUsername = async (request) => {
  try {
    const { data } = await httpClient.post(USERS_APP + 'check_unique/', request)
    return data || null
  } catch (error) {
    return error.response.data
  }
}
export const postIsPasswordValid = async (request) => {
  try {
    const { data } = await httpClient.post(USERS_APP + 'check_password/', request)
    return data || null
  } catch (error) {
    return error.response
  }
}
export const getIndividualStatistics = async (request) => {
  try {
    const { data } = await httpClient.get(USERS_APP + 'stats_individual/', request)
    return data.data || null
  } catch (error) {
    return error.response
  }
}
