import { getAirflowTasks, getAirflowTasksDetails } from '@api/airflow'
import { stateResetMutator } from '@mixins/store-common-methods'


const initialState = () => {
  return {
    airflow_tasks: [],
    airflow_tasks_count: 1,
    airflow_tasks_retrieved_limit: 15,
    airflow_tasks_details: [],
    airflow_tasks_details_count: 0,
    airflow_tasks_details_retrieved_limit: 8,
    current_scheduler: 0


  }
}


export default {

  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_AIRFLOW_TASKS (state, { airflowTasks }) {
      state.airflow_tasks = airflowTasks
    },
    SET_AIRFLOW_TASKS_COUNT (state, { airflowTasksCount }) {
      state.airflow_tasks_count = airflowTasksCount
    },
    SET_AIRFLOW_TASKS_DETAILS (state, { airflowTasksDetails }) {
      state.airflow_tasks_details = airflowTasksDetails
    },
    SET_AIRFLOW_TASKS_DETAILS_COUNT (state, { airflowTasksDetailsCount }) {
      state.airflow_tasks_details_count = airflowTasksDetailsCount
    }
  },
  actions: {
    async retrieveAirflowTasks ({ commit, getters }, { page, schedulerId }) {
      const limit = getters.getAirflowTasksRetrieverLimit
      let query = `scheduler__id=${schedulerId}&limit=${limit}&offset=${limit * page}`
      const data = await getAirflowTasks(query)
      if (data) {
        commit('SET_AIRFLOW_TASKS_COUNT', { airflowTasksCount: data.count })

        commit('SET_AIRFLOW_TASKS', { airflowTasks: data.results })
      }
    },

    async retrieveAirflowTasksDetails ({ commit, getters }, { page, taskId }) {
      const limit = getters.getAirflowTasksDetailsRetrieverLimit
      let query = `airflow_tasks__id=${taskId}&limit=${limit}&offset=${limit * page}`
      const data = await getAirflowTasksDetails(query)
      if (data) {
        commit('SET_AIRFLOW_TASKS_DETAILS_COUNT', { airflowTasksDetailsCount: data.count })
        commit('SET_AIRFLOW_TASKS_DETAILS', { airflowTasksDetails: data.results })
      }
    }

  },


  getters: {
    getAirflowTasks: state => state.airflow_tasks,
    getAirflowTasksCount: state => state.airflow_tasks_count,
    getAirflowTasksRetrieverLimit: state => state.airflow_tasks_retrieved_limit,
    getAirflowTasksDetails: state => state.airflow_tasks_details,
    getAirflowTasksDetailsCount: state => state.airflow_tasks_details_count,
    getAirflowTasksDetailsRetrieverLimit: state => state.airflow_tasks_details_retrieved_limit,
    getCurrentScheduler: state => state.current_scheduler


  }
}
