import { httpClient } from '@api/client'

const SCHEDULER_APP = '/scheduler-jobs/'
const SUBCATEGORIES = '/subcategories/'
const CATEGORIES = '/categories/'

export const getSchedulers = async (query) => {
  try {
    const { data } = await httpClient.get(`${SCHEDULER_APP}?` + query)
    return data || null
  } catch (error) {
    return null
  }
}

export const deleteScheduler = async (id) => {
  try {
    await httpClient.delete(`${SCHEDULER_APP}${id}/`)
    return true
  } catch (error) {
    return null
  }
}

export const createScheduler = async (scheduled, subcategory, email) => {
  const postData = {
    scheduled: scheduled,
    subcategory: subcategory,
    recipient_email: email,
    status: true
  }
  try {
    const { data } = await httpClient.post(SCHEDULER_APP, postData)
    return data || null
  } catch (error) {
    return null
  }
}

export const patchScheduler = async (id, scheduled, email, subcategory, activeStatus) => {
  const patchData = {
    scheduled: scheduled,
    recipient_email: email,
    subcategory: subcategory,
    status: activeStatus
  }

  try {
    const { data } = await httpClient.patch(`${SCHEDULER_APP}${id}/`, patchData)
    return data || null
  } catch (error) {
    return null
  }
}


export const getSubcategories = async (id) => {
  try {
    const { data } = await httpClient.post(`${SUBCATEGORIES}filtered/`, { category_filter: id })
    return data.data || null
  } catch (error) {
    return null
  }
}

export const getCategories = async () => {
  try {
    const { data } = await httpClient.get(CATEGORIES)
    return data || null
  } catch (error) {
    return null
  }
}
