import { stateResetMutator } from '@mixins/store-common-methods'
import {
  getSpellcheckJob,
  getSpellcheckJobsStatistics,
  getSpellCheckJobs,
  postSpellcheckJob,
  spellCheck,
  patchSpellcheckJob,
  wordDocumentSpellCheck,
  updateDialectFiles
} from '@api/spellcheck-jobs'

const initialState = () => {
  return {
    current_job_id: null,
    title: '',
    original_text: '',
    html_text: '',
    errors_count: '',
    source_url: '',
    image_url: '',
    suggestions: {},
    reg_exp: '',
    current_text: '',
    current_html: '',
    mistakes_count: 0,
    spellcheck_jobs_limit: 16,
    spellcheck_jobs: [],
    current_page: 0,
    search_value: '',
    spellcheck_jobs_count: 0
  }
}

export default {
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_SUGGESTIONS (state, { suggestions }) {
      state.suggestions = suggestions
    },
    SET_REG_EXP (state, { regExp }) {
      state.reg_exp = regExp
    },
    GLOBAL_SETTER (state, { data }) {
      for (const key of Object.keys(data)) {
        if (key === 'id') {
          state.current_job_id = data[key]
        } else if (key === 'title') {
          state.title = data[key]
        } else if (key === 'original_text') {
          state.original_text = data[key]
          state.current_text = data['revised_text'] ? data['revised_text'] : data['original_text']
        } else if (key === 'errors_count') {
          state.errors_count = data[key]
        } else if (key === 'html_text') {
          state.html_text = data[key]
        } else if (key === 'link') {
          state.source_url = data[key]
        } else if (key === 'image_url') {
          state.image_url = data[key]
        }
      }
    },
    SET_CURRENT_HTML (state, { html }) {
      state.current_html = html
    },
    SET_MISTAKES_COUNT (state, { mistakes }) {
      state.mistakes_count = mistakes
    },
    SET_CURRENT_TEXT (state, { current }) {
      state.current_text = current
    },
    SET_SPELLCHECK_JOBS (state, { newData }) {
      if (newData === null) state.spellcheck_jobs = []
      else state.spellcheck_jobs.push(...newData)
    },
    SET_CURRENT_PAGE (state, { currentPage }) {
      state.current_page = currentPage
    },
    SET_SEARCH_VALUE (state, { search }) {
      state.search_value = search
    },
    SET_SPELL_CHECK_JOBS_COUNT (state, { dataCount }) {
      state.spellcheck_jobs_count = dataCount
    }
  },
  actions: {
    resetSpellcheckJobsState ({ commit }) {
      commit('STATE_RESET')
    },
    setSuggestionsAndRegExp ({ commit }, { suggestions }) {
      commit('SET_SUGGESTIONS', { suggestions })
      const apiKeyList = Object.keys(suggestions)
      let partOfTheWord = []
      for (const key of apiKeyList) {
        let tempPartOfTheWord = ''
        if (key[0] === ' ' || key[key.length - 1] === ' ') {
          if (key[key.length - 1] === ' ') {
            tempPartOfTheWord = `[ŽžĐđŠšĆćČča-zA-z]*` + key.trim() // add all letters to regex from the right side
          } else {
            tempPartOfTheWord = key.trim()
          }
          if (key[0] === ' ') {
            tempPartOfTheWord = tempPartOfTheWord + `[ŽžĐđŠšĆćČča-zA-z]*` // add all letters to regex from the left side
          }
          partOfTheWord.push(tempPartOfTheWord)
        } else {
          partOfTheWord.push(key)
        }
      }
      let regExp = ''
      if (apiKeyList.length > 0 || partOfTheWord.length > 0) {
        regExp = RegExp(`(?<=[ ,.:;!?"'“„\n]|^)(` + partOfTheWord.join('|') + `)(?=[ !?,.:;"'”\n]|$)`)
      }
      commit('SET_REG_EXP', { regExp })
    },
    async updateCurrentPage ({ commit, getters }) {
      let currentPage = getters.getCurrentPage + 1
      await commit('SET_CURRENT_PAGE', { currentPage })
    },
    async processUpdateDialectFiles () {
      await updateDialectFiles()
    },
    async listSpellcheckJobs ({ commit, getters }, { search }) {
      if (search === null && search !== '') search = getters.getSearchValue
      const limit = getters.getSpellcheckJobsLimit
      if (getters.getSearchValue !== search || getters.getCurrentPage === 0) {
        commit('SET_SPELLCHECK_JOBS', { newData: null })
        commit('SET_CURRENT_PAGE', { currentPage: 0 })
      }
      let query = `?limit=${limit}&offset=${limit * getters.getCurrentPage}`
      if (search) {
        query = query + `&search=${search}`
      }
      let data = await getSpellCheckJobs(query)
      if (data) {
        commit('SET_SPELL_CHECK_JOBS_COUNT', { dataCount: data.count })
      } else {
        data = {
          results: []
        }
      }
      if (getters.getSpellcheckJobs.length < data.count) { // are all spellcheck jobs loaded
        let newData = data.results.map(elem => {
          const dateCreatedDate = new Date(Date.parse(elem['date_created']))
          let dateCreatedStr = dateCreatedDate.getDate() + '/' + (dateCreatedDate.getMonth() + 1) + '/' + dateCreatedDate.getFullYear() + ' ' + (dateCreatedDate.getHours() + 1) + ':' + dateCreatedDate.getMinutes()
          const title = elem.title.length > 35 ? elem.title.slice(0, 35) + '...' : elem.title
          return {
            id: elem['id'],
            title: title,
            name: elem['creator_details']['name'],
            errorsCount: elem['errors_count'],
            date: dateCreatedStr,
            imageUrl: elem['image_url']
          }
        })
        commit('SET_SPELLCHECK_JOBS', { newData })
        commit('SET_SEARCH_VALUE', { search })
      }
    },
    async retrieveSpellcheckJobsStatistics () {
      return getSpellcheckJobsStatistics()
    },
    async retrieveSpellcheckJob ({ commit }, { id }) {
      const data = await getSpellcheckJob(id)
      if (data) {
        commit('GLOBAL_SETTER', { data })
      }
    },
    setCurrentText ({ commit }, { current }) {
      commit('SET_CURRENT_TEXT', { current })
    },
    transformTextToSpellcheckedJobList ({ commit, getters }) { // TODO check name
      const suggestions = getters.getSuggestions
      const regExp = getters.getRegExp
      const suggestionKeys = Object.keys(suggestions)
      let spellcheckJobList = []
      let spellcheckJobListIndex = 0
      let mistakes = 0
      if (regExp !== '') {
        let result = getters.getCurrentText.split(regExp)
        // checking every word in current text
        result = result.map((x, index) => {
          if (suggestionKeys.indexOf(x) >= 0) {
            if (x[0] && x[0] === x[0].toUpperCase()) {
              if (index > 0) {
                // if word is not at the beginning of the sentence and starts with uppercase letter all suggestions will be ignored
                if (!/\.|!|\?|\n|\r/.test(result[index - 1].trim()[result[index - 1].trim().length - 1]) && ((index - 1) !== 0 || result[index - 1] !== '')) return x
              }
            }
            return {
              current: x,
              suggestions: suggestions[x]
            }
          } else if (/^[ŽžĐđŠšĆćČča-zA-z]*$/.test(x.trim())) { // if there is a part of the word inside
            for (const key of suggestionKeys) {
              if (x.includes(key.trim())) {
                let temp = {
                  dialect: suggestions[key].dialect.map(elem => x.replace(key.trim(), elem.trim())),
                  originalWord: x.replace(key.trim(), suggestions[key].originalWord.trim()),
                  spelling: [],
                  color: suggestions[key].color
                }
                return {
                  current: x,
                  suggestions: temp
                }
              }
            }
            return x
          } else {
            return x
          }
        })
        // concatenate parts of results list between two objects and count mistakes
        result.forEach(elem => {
          if (typeof elem === 'string') {
            spellcheckJobList.length === spellcheckJobListIndex
              ? spellcheckJobList[spellcheckJobListIndex] = elem
              : spellcheckJobList[spellcheckJobListIndex] = spellcheckJobList[spellcheckJobListIndex] + elem
          } else {
            mistakes += 1
            if (spellcheckJobList.length !== spellcheckJobListIndex) {
              spellcheckJobListIndex += 1
            }
            spellcheckJobList[spellcheckJobListIndex] = elem
            spellcheckJobListIndex += 1
          }
        })
        commit('SET_MISTAKES_COUNT', { mistakes })
        return spellcheckJobList
      }
      commit('SET_MISTAKES_COUNT', { mistakes })
      return [getters.getCurrentText]
    },
    async createUpdateOrCheckSpellcheckJob ({ commit, getters, dispatch }, flag = false) { // TODO check name
      const currentText = getters.getCurrentText
      const currentHtml = getters.getCurrentHtml
      const htmlText = getters.getHtmlText
      let title = currentText.split('\n')[0].slice(0, 250)
      let htmlForApiRequest = ''
      if (htmlText !== currentHtml && currentHtml !== '') {
        htmlForApiRequest = currentHtml
      } else {
        htmlForApiRequest = htmlText
      }
      let data
      if (flag) {
        data = await spellCheck(currentText)
      } else if (getters.getCurrentSpellcheckJobId) {
        const request = {
          'html_text': htmlForApiRequest,
          'revised_text': currentText,
          'title': title,
          'link': getters.getSourceLink,
          'is_link': !!getters.getSourceLink,
          'errors_count': getters.getMistakesCount,
          'image_url': getters.getImageUrl
        }
        data = await patchSpellcheckJob(getters.getCurrentSpellcheckJobId, request)
      } else {
        const originalText = getters.getOriginalText
        const request = {
          'original_text': !originalText ? currentText : originalText,
          'html_text': htmlForApiRequest,
          'revised_text': currentText,
          'title': title,
          'link': getters.getSourceLink,
          'is_link': !!getters.getSourceLink,
          'errors_count': getters.getMistakesCount,
          'image_url': getters.getImageUrl
        }
        data = await postSpellcheckJob(request)
      }
      if (data) {
        let suggestions = {}
        if (Object.keys(data).indexOf('suggestions') > -1) {
          suggestions = data['suggestions']
        }
        if (!flag) {
          return true
        }
        commit('GLOBAL_SETTER', { data })
        dispatch('spellcheckJob', suggestions)
      } else {
        return false
      }
    },
    setCurrentHtml ({ commit }, { html }) {
      commit('SET_CURRENT_HTML', { html })
    },
    spellcheckJob ({ commit, dispatch, getters }, suggestionsApiResponse) {
      let currentText = getters.getCurrentText
      if (currentText === '') {
        return
      }
      if (suggestionsApiResponse) {
        let spellingAndDialectList = suggestionsApiResponse // copied because dialect mappings are needed
        let parsedSuggestions = {} // object containing whole words as keys for easier text parsing in the future
        let usedKeys = [] // used for cases when there is only spelling mistakes in some words
        const spellingKeysList = Object.keys(spellingAndDialectList.spelling)
        for (const key of Object.keys(spellingAndDialectList.dialect)) {
          if (/^\s.*\s$/.test(key)) { // whole word mistakes
            if (spellingAndDialectList.dialect[key].length > 0) {
              let tempObj = {}
              let word = spellingAndDialectList.dialect[key][0].trim()
              let regex = new RegExp(`(?<=[ ,.:;"„'“\n]|^)(` + key.trim() + `)(?=[ ,.:;''”?!\n]|$)`, 'g')
              currentText = currentText.replaceAll(regex, word) // replace all dialect mistakes
              tempObj['spelling'] = []
              // finding spelling errors
              for (const keySpell of spellingKeysList) {
                if (keySpell === key.trim()) {
                  tempObj['spelling'] = spellingAndDialectList.spelling[keySpell]
                  usedKeys.push(keySpell)
                  break
                }
              }
              tempObj['dialect'] = spellingAndDialectList.dialect[key].map(x => x.trim())
              tempObj['originalWord'] = key.trim()
              tempObj['color'] = 'info'
              parsedSuggestions[word] = tempObj
            }
          } else {
            // dialect error that is not a whole word
            if (spellingAndDialectList.dialect[key].length > 0) {
              let word = spellingAndDialectList.dialect[key][0]
              currentText = currentText.replaceAll(key, word) // replace dialect mistakes in current text
              let regex
              let dialectErrorOnlyName = word
              // check dialect error type (ends with, starts or in the middle)
              if (/^.*\s$/.test(key)) {
                regex = new RegExp('^.*' + key.trim() + '$')
              } else if (/^\s.*$/.test(key)) {
                regex = new RegExp('^' + key.trim() + '.*$')
              } else {
                regex = new RegExp('^.*' + key.trim() + '.*$')
                dialectErrorOnlyName = ' ' + word.trim() + ' '
              }
              // check for occurrences in spelling errors
              for (const keySpell of Object.keys(spellingAndDialectList.spelling)) {
                if (regex.test(keySpell)) {
                  let tempObjFor = {}
                  tempObjFor['dialect'] = spellingAndDialectList.dialect[key].map(x => keySpell.replace(key, x.trim())) // adjust dialect to spelling error
                  tempObjFor['originalWord'] = keySpell
                  tempObjFor['spelling'] = spellingAndDialectList.spelling[keySpell]
                  tempObjFor['color'] = 'info'
                  parsedSuggestions[keySpell.replace(key.trim(), word.trim())] = tempObjFor // adjust obj name to dialect error
                  usedKeys.push(keySpell)
                }
              }
              // for potential dialect errors that dont have spelling errors
              parsedSuggestions[dialectErrorOnlyName] = {
                dialect: spellingAndDialectList.dialect[key],
                originalWord: key.trim(),
                spelling: [],
                color: 'info'
              }
            }
          }
        }
        for (const key of Object.keys(spellingAndDialectList.excluded)) {
          if (spellingAndDialectList.excluded[key].length > 0) {
            let tempObj = {}
            let word = spellingAndDialectList.excluded[key][0].trim()
            let regex = new RegExp(`(?<=[ ,.:;"„'“\n]|^)(` + key.trim() + `)(?=[ ,.:;''”?!\n]|$)`, 'g')
            currentText = currentText.replaceAll(regex, word) // replace all dialect mistakes
            tempObj['spelling'] = []
            // finding spelling errors
            for (const keySpell of spellingKeysList) {
              if (keySpell === key.trim()) {
                tempObj['spelling'] = spellingAndDialectList.spelling[keySpell]
                usedKeys.push(keySpell)
                break
              }
            }
            tempObj['excluded'] = spellingAndDialectList.excluded[key].map(x => x.trim())
            tempObj['dialect'] = []
            tempObj['originalWord'] = key.trim()
            tempObj['color'] = 'success'
            parsedSuggestions[word] = tempObj
          }
        }
        const newSpellingKeysList = Object.keys(spellingAndDialectList.spelling)
        // update the list if a word only has spelling mistakes
        for (const keySpell of newSpellingKeysList) {
          if (!(usedKeys.indexOf(keySpell) > -1)) {
            let tempObj = {}
            tempObj['dialect'] = []
            tempObj['originalWord'] = keySpell
            tempObj['spelling'] = spellingAndDialectList.spelling[keySpell]
            tempObj['color'] = 'danger'

            parsedSuggestions[keySpell] = tempObj
          }
        }
        commit('SET_CURRENT_TEXT', { current: currentText })
        dispatch('setSuggestionsAndRegExp', { suggestions: parsedSuggestions })
        return
      }
      dispatch('setSuggestionsAndRegExp', { suggestions: [] })
    },
    async processWordDocumentSpellCheck (_, { file }) {
      return wordDocumentSpellCheck(file)
    }
  },
  getters: {
    getSuggestions: state => state.suggestions,
    getCurrentText: state => state.current_text,
    getRegExp: state => state.reg_exp,
    getMistakesCount: state => state.mistakes_count,
    getCurrentSpellcheckJobId: state => state.current_job_id,
    getOriginalText: state => state.original_text,
    getCurrentHtml: state => state.current_html,
    getHtmlText: state => state.html_text,
    getSourceLink: state => state.source_url,
    getImageUrl: state => state.image_url,
    getSpellcheckJobsLimit: state => state.spellcheck_jobs_limit,
    getSpellcheckJobs: state => state.spellcheck_jobs,
    getCurrentPage: state => state.current_page,
    getSearchValue: state => state.search_value,
    getSpellcheckJobsCount: state => state.spellcheck_jobs_count
  }
}
