import { getIndividualStatistics, getUsers, getUser, postUser, patchUser, deleteUser, getUserRoles, getUserLanguages,
  postNewUserPassword, postIsUniqueEmailOrUsername, postIsPasswordValid } from '@api/users'
import { stateResetMutator, resetStateAction } from '@mixins/store-common-methods'
const initialState = () => {
  return {
    password_error: '',
    avatar: null,
    language: null,
    current_user: null,
    users_retriever_limit: 5,
    users_count: 0,
    users: null
  }
}
export default {
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_PASSWORD_ERROR (state, { passError }) {
      state.password_error = [...passError]
    },
    SET_AVATAR (state, { avatar }) {
      state.avatar = avatar
    },
    SET_LANGUAGE (state, { language }) {
      state.language = language
    },
    SET_USER (state, { currentUser }) {
      state.current_user = currentUser
    },
    SET_USERS (state, { users }) {
      state.users = users
    },
    SET_USERS_COUNT (state, { usersCount }) {
      state.users_count = usersCount
    }
  },
  actions: {
    resetState: resetStateAction(),
    async listUsers () {
      return getUsers()
    },
    async retrieveUsers ({ commit, getters }, { page, search }) {
      const limit = getters.getUsersRetrieverLimit
      let query = `limit=${limit}&offset=${limit * page}`
      if (search) {
        query = query + `&search=${search}`
      }
      const data = await getUsers(query)
      if (data) {
        commit('SET_USERS_COUNT', { usersCount: data.count })

        commit('SET_USERS', { users: data.results })
      }
    },
    async retrieveUser ({ commit }) {
      let info = await getUser()
      if (info) {
        commit('SET_AVATAR', { avatar: info['avatar'] })
        let langCode = 'cnr'
        if (info['language_details'] && info['language_details']['language_code']) {
          langCode = info['language_details']['language_code']
        }
        commit('SET_LANGUAGE', { language: langCode })
        commit('SET_USER', { currentUser: info })
      } else {
        info = {}
      }
      return info
    },
    async createUser (_, { data }) {
      return postUser(data)
    },
    async setUsersPassword (_, { data }) {
      return postNewUserPassword(data)
    },
    async partialUpdateUser (_, { id, data }) {
      if (window.location.pathname.includes('rjecnik')) {
        patchUser(id, data)
        window.location.reload()
      }
      return patchUser(id, data)
    },
    async destroyUser (_, { id }) {
      return deleteUser(id)
    },
    async retrieveUserRoles () {
      return getUserRoles()
    },
    async retrieveUserLanguages () {
      return getUserLanguages()
    },
    async isUniqueEmailOrUsername (_, { data }) {
      return postIsUniqueEmailOrUsername(data)
    },
    async isValidPassword ({ commit, getters }, { data }) {
      let response = await postIsPasswordValid(data)
      let passError = ''
      if (response.data !== true) {
        passError = response.data.password
        commit('SET_PASSWORD_ERROR', { passError })
        return response.data
      }
      commit('SET_PASSWORD_ERROR', { passError })
      return response.data
    },
    async retrieveIndividualStatistics () {
      let data = await getIndividualStatistics()
      if (data['latest_article'] !== '/') {
        const dateArticleDate = new Date(Date.parse(data['latest_article']))
        data['latest_article'] = dateArticleDate.getDate() + '/' + (dateArticleDate.getMonth() + 1) + '/' + dateArticleDate.getFullYear() + ' ' + (dateArticleDate.getHours() + 1) + ':' + dateArticleDate.getMinutes()
      }
      if (data['latest_word'] !== '/') {
        const dateWordDate = new Date(Date.parse(data['latest_word']))
        data['latest_word'] = dateWordDate.getDate() + '/' + (dateWordDate.getMonth() + 1) + '/' + dateWordDate.getFullYear() + ' ' + (dateWordDate.getHours() + 1) + ':' + dateWordDate.getMinutes()
      }
      return data
    }
  },
  getters: {
    getPasswordError: state => state.password_error,
    getAvatar: state => state.avatar,
    getCurrentUser: state => state.current_user,
    getLanguage: state => state.language,
    getAllUsers: state => state.users,
    getUsersCount: state => state.users_count,
    getUsersRetrieverLimit: state => state.users_retriever_limit
  }
}
