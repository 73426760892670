import { stateResetMutator, resetStateAction } from '@mixins/store-common-methods'
import { deleteExcludedWord, getExcludedWords, patchExcludedWord, postExcludedWord } from '@api/excluded'
import { cancelTokenSource } from '@api/client'

const initialState = () => {
  return {
    excluded_words: [],
    excluded_words_count: 0,
    cancel_token: null,
    excluded_words_retrieved_limit: 10
  }
}

export default {
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_EXCLUDED_WORDS (state, { words }) {
      state.excluded_words = words
    },
    SET_EXCLUDED_WORDS_COUNT (state, { wordsCount }) {
      state.excluded_words_count = wordsCount
    },
    SET_CANCEL_TOKEN (state, { cancelToken }) {
      state.cancel_token = cancelToken
    }
  },
  actions: {
    resetState: resetStateAction(),
    async retrieveExcludedWords ({ commit, getters }, { page, search }) {
      // make cancelToken
      const cancelToken = cancelTokenSource()
      commit('SET_CANCEL_TOKEN', { cancelToken })
      // make filtering query
      const limit = getters.getWordsRetrievedLimit
      let query = `limit=${limit}&offset=${limit * page}`
      if (search) {
        query = query + `&search=${search}`
      }
      const data = await getExcludedWords(query, cancelToken.token)
      if (data) {
        commit('SET_EXCLUDED_WORDS_COUNT', { wordsCount: data.count })
        commit('SET_EXCLUDED_WORDS', { words: data.results })
      }
    },
    async createExcludedWord (_, { originalWord, originalLang, excludedWord, excludedLang }) {
      return postExcludedWord(originalWord, originalLang, excludedWord, excludedLang)
    },
    async partialUpdateExcludedWord (_, { id, originalWord, originalLang, excludedWord, excludedLang }) {
      return patchExcludedWord(id, originalWord, originalLang, excludedWord, excludedLang)
    },
    async destroyExcludedWord (_, { id }) {
      return deleteExcludedWord(id)
    }
    // async cancelSearch ({ getters }) {
    //   const cancelToken = getters.getCancelToken
    //   if (cancelToken) {
    //     cancelToken.cancel()
    //   }
    // }
  },
  getters: {
    getExcludedWords: state => state.excluded_words,
    getExcludedWordsCount: state => state.excluded_words_count,
    getCancelToken: state => state.cancel_token,
    getExcludedWordsRetrievedLimit: state => state.excluded_words_retrieved_limit
  }
}
