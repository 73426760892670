import App from '@/main'
import { httpClient } from '@api/client'

// Spell Endpoints
const SPELLCHECK_JOBS = '/spellcheck-jobs/'
const CHECK_ACTION = 'check/'
const STATS_ACTION = 'stats/'
const WORD_CHECK_ACTION = 'document/'
const UPDATE_DIALECT_ACTION = 'dialect/'

export const getSpellcheckJobsStatistics = async () => {
  try {
    const { data } = await httpClient.get(`${SPELLCHECK_JOBS}${STATS_ACTION}`)
    return data.data || []
  } catch (error) {
    return []
  }
}

export const getSpellCheckJobs = async (query) => {
  try {
    const { data } = await httpClient.get(`${SPELLCHECK_JOBS}${query}&fields=id,title,editor_details,creator_details,errors_count,date_created,image_url`)
    return data || null
  } catch (error) {
    return null
  }
}

export const getSpellcheckJob = async (id) => {
  try {
    const { data } = await httpClient.get(`${SPELLCHECK_JOBS}${id}/`)
    return data || null
  } catch (error) {
    return null
  }
}

export const postSpellcheckJob = async (request) => {
  if (!request['revised_text']) {
    App.$buefy.notification.open({ type: 'danger', message: 'Tekst ne može biti prazan.' })
    return null
  }
  try {
    const { data } = await httpClient.post(SPELLCHECK_JOBS, request)
    return data || null
  } catch (error) {
    return null
  }
}

export const patchSpellcheckJob = async (id, request) => {
  if (!request['revised_text']) {
    App.$buefy.notification.open({ type: 'danger', message: 'Tekst ne može biti prazan.' })
    return null
  }
  try {
    const { data } = await httpClient.patch(SPELLCHECK_JOBS + `${id}` + '/', request)
    return data || null
  } catch (error) {
    return null
  }
}

export const spellCheck = async (text) => {
  if (!text) {
    App.$buefy.notification.open({ type: 'danger', message: 'Tekst ne može biti prazan.' })
    return null
  }
  try {
    const { data } = await httpClient.post(`${SPELLCHECK_JOBS}${CHECK_ACTION}`, { 'link_or_text': text })
    return data.data || null
  } catch (error) {
    return null
  }
}

export const wordDocumentSpellCheck = async (file) => {
  let formData = new FormData()
  formData.append('file_field', file)

  try {
    const { data } = await httpClient.post(`${SPELLCHECK_JOBS}${WORD_CHECK_ACTION}`, formData,
      { responseType: 'blob' })
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    const filenameList = file.name.split('.')
    link.setAttribute('download',
      `${filenameList[0]}_spellchecked.${filenameList[filenameList.length - 1]}`)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
  }
}

export const updateDialectFiles = async () => {
  try {
    const { data } = await httpClient.get(`${SPELLCHECK_JOBS}${UPDATE_DIALECT_ACTION}`)
    return data.data || null
  } catch (error) {
    return null
  }
}
