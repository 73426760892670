import store from '@store/store'
// Layout Pages
const PublicPageLayout = () => import('@components/layouts/public/PublicPageLayout')
const PrivatePageLayout = () => import('@components/layouts/private/PrivatePageLayout')

// Lazy Loading Routes - Public Pages
const TheLogin = () => import(/* webpackChunkName: "public" */ '@pages/Auth/TheLogin')
const ThePasswordReset = () => import(/* webpackChunkName: "public" */ '@pages/PasswordReset/ThePasswordReset')

// Error-Pages
const TheNotFound = () => import(/* webpackChunkName: "public" */ '@pages/Errors/TheNotFound')

// Lazy Loading Routes - Private Pages
const TheDashboard = () => import(/* webpackChunkName: "private" */ '@pages/Dashboard/TheDashboard')
const TheEditor = () => import(/* webpackChunkName: "private" */ '@pages/Editor/TheEditor')
const TheDictionary = () => import(/* webpackChunkName: "private" */ '@pages/Dictionary/TheDictionary')
const TheUsers = () => import(/* webpackChunkName: "private" */ '@pages/Users/TheUsers')
const TheProfile = () => import(/* webpackChunkName: "private" */ '@pages/Profile/TheProfile')
const TheAirflow = () => import(/* webpackChunkName: "private" */ '@pages/Airflow/TheAirflow')
const TheScheduler = () => import(/* webpackChunkName: "private" */ '@pages/Scheduler/TheSchedulers')
const TheDialect = () => import(/* webpackChunkName: "private" */ '@pages/Dialects/TheDialect')
const TheExcluded = () => import(/* webpackChunkName: "private" */ '@pages/Excluded/TheExcluded')


// Use this guard to redirect users from Front pages if already logged in
const checkIfAlreadyAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next({ name: 'TheDashboard' })
  } else {
    next()
  }
}

let publicRoutes = [
  {
    path: '',
    component: PublicPageLayout,
    children: [
      {
        path: '',
        name: 'TheLogin',
        components: { default: TheLogin },
        beforeEnter: checkIfAlreadyAuthenticated
      },
      {
        path: '/settings/change-password',
        name: 'ThePasswordReset',
        components: { default: ThePasswordReset },
        beforeEnter: checkIfAlreadyAuthenticated
      }
    ]
  }
]

// Specify routes as modules
let dashboardRoute = {
  path: '',
  name: 'TheDashboardIndex',
  redirect: { name: 'TheDashboard' },
  component: PrivatePageLayout,
  children: [
    {
      path: '/dash',
      name: 'TheDashboard',
      isAdminOnly: false,
      components: { default: TheDashboard }
    },
    {
      path: '/editor',
      name: 'TheEditor',
      isAdminOnly: false,
      components: { default: TheEditor }
    },
    {
      path: '/rjecnik',
      name: 'TheDictionary',
      isAdminOnly: false,
      components: { default: TheDictionary }
    },
    {
      path: '/excluded',
      name: 'TheExcluded',
      isAdminOnly: false,
      components: { default: TheExcluded }
    },
    {
      path: '/korisnici',
      name: 'TheUsers',
      isAdminOnly: true,
      components: { default: TheUsers }
    },
    {
      path: '/profil',
      name: 'TheProfile',
      isAdminOnly: false,
      components: { default: TheProfile }
    },
    {
      path: '/zakazivanje',
      name: 'TheScheduler',
      isAdminOnly: true,
      components: { default: TheScheduler }
    },
    {
      path: '/detalji-zakazivanja',
      name: 'TheAirflow',
      isAdminOnly: true,
      components: { default: TheAirflow }
    },
    {
      path: '/dialect',
      name: 'TheDialect',
      isAdminOnly: false,
      components: { default: TheDialect }
    }
  ]
}

// Load each private module
let privateRoutes = [
  dashboardRoute
]

// Adding requiresAuth: true on every back route
privateRoutes.forEach(function (part, index) {
  // Loop through children and add requiresAuth in meta
  this[index].children.forEach(function (part, index) {
    if (this[index].hasOwnProperty('meta')) {
      this[index]['meta']['requiresAuth'] = true
    } else {
      this[index]['meta'] = { requiresAuth: true }
    }
    if (this[index]['isAdminOnly']) {
      this[index]['meta']['isAdminOnly'] = true
    }
  }, this[index].children)
}, privateRoutes)

const routes = [
  ...publicRoutes,
  ...privateRoutes,
  {
    path: '*',
    name: 'TheNotFound',
    component: TheNotFound
  }]

export default routes
