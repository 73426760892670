import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import router from '@routes/router'
import store from '@store/store'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
import 'buefy/dist/buefy.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@assets/styles/theme.sass'

Vue.use(Buefy)
Vue.use(VueRouter)
// Sentry.init({
//   Vue,
//   dsn: 'https://331392d5927248738c2a35f9b048abd9@sentry.bixbit.me/8',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: parseFloat(process.env.VUE_APP_TRACES_SAMPLE_RATE)
// })
const VueApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default VueApp
