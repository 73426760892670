import { httpClient } from '@api/client'

// Words Endpoints
const WORDS_APP = '/words/'

export const getWords = async (query, cancelToken, type) => {
  try {
    const { data } = await httpClient.get(`${WORDS_APP}?is_ignored=${type === 'ignored'}&` + query, { cancelToken: cancelToken })
    return data.data || null
  } catch (error) {
    return null
  }
}

export const postWord = async (word, type, lang) => {
  const postData = {
    word: word,
    'is_ignored': type === 'ignored',
    lang: lang
  }
  try {
    const { data } = await httpClient.post(`${WORDS_APP}`, postData)
    return data.success || null
  } catch (error) {
    return null
  }
}

export const patchWord = async (id, word, type) => {
  try {
    const { data } = await httpClient.patch(`${WORDS_APP}` + `${id}` + '/', { word, is_ignored: type === 'ignored' })
    return data || null
  } catch (error) {
    return null
  }
}

export const deleteWord = async (id) => {
  try {
    await httpClient.delete(`${WORDS_APP}${id}/`)
    return true
  } catch (error) {
    return null
  }
}

