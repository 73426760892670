export const stateResetMutator = function (initialState) {
  return function (state) {
    let fresh = initialState()
    Object.assign(state, fresh)
  }
}

export const resetStateAction = function () {
  return function ({ commit }) { commit('STATE_RESET') }
}

export const setLoadingMutator = function () {
  return function (state, loadingStatus) {
    state.loading = loadingStatus
  }
}

export const setLoadingAction = function () {
  return function ({ commit }, loadingStatus) { commit('loading_set', loadingStatus) }
}

export const setFiltersMutator = function () {
  return function (state, filters) {
    state.filters = filters
  }
}

export const setFiltersAction = function () {
  return function ({ commit }, filters) { commit('filters_set', filters) }
}

export const setOrderingMutator = function () {
  return function (state, ordering) {
    state.ordering = ordering
  }
}

export const setOrderingAction = function () {
  return function ({ commit }, ordering) { commit('ordering_set', ordering) }
}

export const setSearchMutator = function () {
  return function (state, search) {
    state.search = search
  }
}

export const setSearchAction = function () {
  return function ({ commit }, search) { commit('search_set', search) }
}

export default {
  stateResetMutator,
  resetStateAction,
  setLoadingMutator,
  setLoadingAction,
  setFiltersMutator,
  setFiltersAction,
  setOrderingMutator,
  setOrderingAction,
  setSearchMutator,
  setSearchAction
}
