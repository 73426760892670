import { httpClient } from '@api/client'

const TASKS_APP = '/airflow-task/'
const TASKS_DETAILS_APP = '/airflow-task-details/'

export const getAirflowTasks = async (query) => {
  try {
    const { data } = await httpClient.get(`${TASKS_APP}?` + query)
    return data || null
  } catch (error) {
    return null
  }
}

export const getAirflowTasksDetails = async (query) => {
  try {
    const { data } = await httpClient.get(`${TASKS_DETAILS_APP}?` + query)
    return data || null
  } catch (error) {
    return null
  }
}

