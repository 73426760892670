import { stateResetMutator, resetStateAction } from '@mixins/store-common-methods'
import { deleteDialectWord, getDialectsWords, patchDialectWord, postDialectWord } from '@api/dialects'
import { cancelTokenSource } from '@api/client'

const initialState = () => {
  return {
    dialect_words: [],
    dialect_words_count: 0,
    cancel_token: null,
    dialect_words_retrieved_limit: 10
  }
}

export default {
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_DIALECT_WORDS (state, { words }) {
      state.dialect_words = words
    },
    SET_DIALECT_WORDS_COUNT (state, { wordsCount }) {
      state.dialect_words_count = wordsCount
    },
    SET_CANCEL_TOKEN (state, { cancelToken }) {
      state.cancel_token = cancelToken
    }
  },
  actions: {
    resetState: resetStateAction(),
    async retrieveDialectWords ({ commit, getters }, { page, search }) {
      // make cancelToken
      const cancelToken = cancelTokenSource()
      commit('SET_CANCEL_TOKEN', { cancelToken })
      // make filtering query
      const limit = getters.getWordsRetrievedLimit
      let query = `limit=${limit}&offset=${limit * page}`
      if (search) {
        query = query + `&search=${search}`
      }
      const data = await getDialectsWords(query, cancelToken.token)
      if (data) {
        commit('SET_DIALECT_WORDS_COUNT', { wordsCount: data.count })
        commit('SET_DIALECT_WORDS', { words: data.results })
      }
    },
    async createDialectWord (_, { wordCnr, wordRs }) {
      return postDialectWord(wordCnr, wordRs)
    },
    async partialUpdateDialectWord (_, { id, wordCnr, wordRs }) {
      return patchDialectWord(id, wordCnr, wordRs)
    },
    async destroyDialectWord (_, { id }) {
      return deleteDialectWord(id)
    }
    // async cancelSearch ({ getters }) {
    //   const cancelToken = getters.getCancelToken
    //   if (cancelToken) {
    //     cancelToken.cancel()
    //   }
    // }
  },
  getters: {
    getDialectsWords: state => state.dialect_words,
    getDialectWordsCount: state => state.dialect_words_count,
    getCancelToken: state => state.cancel_token,
    getDialectWordsRetrievedLimit: state => state.dialect_words_retrieved_limit
  }
}
