import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@store/modules/auth'
import spellcheckJobs from '@store/modules/spellcheck-jobs'
import words from '@store/modules/words'
import users from '@store/modules/users'
import schedulers from '@store/modules/schedulers'
import airflow from '@store/modules/airflow'
import dialects from '@store/modules/dialects'
import excluded from '@store/modules/excluded'

Vue.use(Vuex)

// Load only essential modules - others will be dynamically loaded in BackLayout - beforeCreate()
export default new Vuex.Store({
  modules: {
    auth,
    spellcheckJobs,
    words,
    users,
    schedulers,
    airflow,
    dialects,
    excluded
  }
})
