import { httpClient } from '@api/client'

// Words Endpoints
const EXCLUDED_APP = '/excluded/'

export const getExcludedWords = async (query) => {
  try {
    const { data } = await httpClient.get(`${EXCLUDED_APP}?${query}`)
    return data || null
  } catch (error) {
    return null
  }
}

export const postExcludedWord = async (originalWord, originalLang, excludedWord, excludedLang) => {
  const postData = {
    original_word: originalWord,
    original_lang: originalLang,
    excluded_word: excludedWord,
    excluded_lang: excludedLang
  }
  try {
    const { data } = await httpClient.post(`${EXCLUDED_APP}`, postData)
    return data || null
  } catch (error) {
    return null
  }
}

export const deleteExcludedWord = async (id) => {
  try {
    await httpClient.delete(`${EXCLUDED_APP}${id}/`)
    return true
  } catch (error) {
    return null
  }
}

export const patchExcludedWord = async (id, originalWord, originalLang, excludedWord, excludedLang) => {
  try {
    const { data } = await httpClient.patch(`${EXCLUDED_APP}` + `${id}/`, {
      original_word: originalWord,
      original_lang: originalLang,
      excluded_word: excludedWord,
      excluded_lang: excludedLang })
    return data || null
  } catch (error) {
    return null
  }
}
