import VueRouter from 'vue-router'
import routes from '@routes/routes'
import store from '@store/store'

// configure router
const router = new VueRouter({
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// IsAuthenticated Guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      next()
    } else {
      next({ name: 'TheLogin' })
    }
  } else {
    next()
  }
  // Check if entered routes is only for Administrators or Operators
  if (to.matched.some(record => record.meta.isAdminOnly)) {
    if (store.getters['auth/getUserRole'] === 'admin') {
      next()
    } else {
      next({ name: 'TheDashboard' })
    }
  } else {
    next()
  }
})

export default router
