import { stateResetMutator, resetStateAction } from '@mixins/store-common-methods'
import { getWords, postWord, patchWord, deleteWord } from '@api/words'
import { cancelTokenSource } from '@api/client'

const initialState = () => {
  return {
    words: [],
    words_count: 0,
    cancel_token: null,
    words_retrieved_limit: 10
  }
}

export default {
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_WORDS (state, { words }) {
      state.words = words
    },
    SET_WORDS_COUNT (state, { wordsCount }) {
      state.words_count = wordsCount
    },
    SET_CANCEL_TOKEN (state, { cancelToken }) {
      state.cancel_token = cancelToken
    }
  },
  actions: {
    resetState: resetStateAction(),
    async retrieveWords ({ commit, getters }, { page, search, type }) {
      // make cancelToken
      const cancelToken = cancelTokenSource()
      commit('SET_CANCEL_TOKEN', { cancelToken })
      // make filtering query
      const limit = getters.getWordsRetrievedLimit
      let query = `limit=${limit}&offset=${limit * page}`
      if (search) {
        query = query + `&search=${search}`
      }
      const data = await getWords(query, cancelToken.token, type)
      if (data) {
        commit('SET_WORDS_COUNT', { wordsCount: data.count })
        commit('SET_WORDS', { words: data.results })
      }
    },
    async createWord (_, { word, type, lang }) {
      return postWord(word, type, lang)
    },
    async partialUpdateWord (_, { id, word, type, lang }) {
      return patchWord(id, word, type, lang)
    },
    async destroyWord (_, { id }) {
      return deleteWord(id)
    },
    async cancelSearch ({ getters }) {
      const cancelToken = getters.getCancelToken
      if (cancelToken) {
        cancelToken.cancel()
      }
    }
  },
  getters: {
    getWords: state => state.words,
    getWordsCount: state => state.words_count,
    getCancelToken: state => state.cancel_token,
    getWordsRetrievedLimit: state => state.words_retrieved_limit
  }
}
