import { getSchedulers, deleteScheduler, getSubcategories, getCategories, createScheduler, patchScheduler } from '@api/scheduler'
import { stateResetMutator } from '@mixins/store-common-methods'

const initialState = () => {
  return {
    schedulers: [],
    schedulers_count: 0,
    schedulers_retrieved_limit: 10
  }
}

export default {
  state: initialState(),
  mutations: {
    STATE_RESET: stateResetMutator(initialState),
    SET_SCHEDULERS (state, { schedulers }) {
      state.schedulers = schedulers
    },
    SET_SCHEDULERS_COUNT (state, { schedulersCount }) {
      state.schedulers_count = schedulersCount
    }
  },
  actions: {
    async retrieveSchedulers ({ commit, getters }, { page, search }) {
      const limit = getters.getSchedulersRetrieverLimit
      let query = `limit=${limit}&offset=${limit * page}`
      if (search) {
        query = query + `&search=${search}`
      }
      const data = await getSchedulers(query)
      if (data) {
        commit('SET_SCHEDULERS_COUNT', { schedulersCount: data.count })

        commit('SET_SCHEDULERS', { schedulers: data.results })
      }
    },
    async destroyScheduler (_, { id }) {
      return deleteScheduler(id)
    },
    async postScheduler (_, { scheduled, subcategory, email }) {
      return createScheduler(scheduled, subcategory, email)
    },
    async updateScheduler (_, { id, scheduled, email, subcategory, activeStatus }) {
      return patchScheduler(id, scheduled, email, subcategory, activeStatus)
    },

    async retrieveSubcategories (_, { id }) {
      return getSubcategories(id)
    },
    async retrieveCategories () {
      return getCategories()
    }

  },

  getters: {
    getSchedulers: state => state.schedulers,
    getSchedulersCount: state => state.schedulers_count,
    getSchedulersRetrieverLimit: state => state.schedulers_retrieved_limit


  }
}
