import App from '@/main'
import axios from 'axios'

const baseUrl = process.env.VUE_APP_API_URL

const httpClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

const requestAuthInterceptor = (config) => {
  config.headers['Authorization'] = App.$store.getters['auth/getToken'] ? 'Token ' + App.$store.getters['auth/getToken'] : ''
  return config
}

// Error Interceptor - processing
const responseErrorInterceptor = async error => {
  // TODO: Fix all notification issues!
  const ignoreRoutes = ['/check_unique/', '/check_password/']
  let isIgnored = ignoreRoutes.some(function (route) {
    return this[0].includes(route)
  }, [error.response.config.url])
  switch (error.response.status) {
    case 400:
    case 403:
      // HACK: Check if Authentication Specific Error
      if (error.response.request.responseURL.includes('/auth/') && !error.response.request.responseURL.includes('forgot-password')) {
        App.$buefy.notification.open({ type: 'is-warning', message: error.response.data.non_field_errors[0] })
      } else {
        // If error has message inside response display it!
        if (error.response.data.non_field_errors && error.response.data.non_field_errors[0] === 'Duplikat.') {
          App.$buefy.notification.open({ type: 'is-danger', message: 'Duplikat.' })
          break
        }
        // if (error.response.data.non_field_errors)
        if (error.response.config.responseType === 'blob') {
          error.response.data = JSON.parse(await error.response.data.text())
        }
        if (Object.prototype.hasOwnProperty.call(error.response.data, 'message')) {
          App.$buefy.notification.open({ type: 'is-warning', message: error.response.data.message })
        } else if (error.response.request.responseURL.includes('/users/') && !isIgnored) {
          App.$buefy.notification.open({ type: 'is-warning', message: 'Pogrešan unos.' })
        } else if (error.response.request.responseURL.includes('/scheduler-jobs/') && !isIgnored) {
          App.$buefy.notification.open({ type: 'is-warning', message: 'Planer već postoji.' })
        } else if (!isIgnored) {
          App.$buefy.notification.open({ type: 'is-warning', message: 'Pokušajte kasnije.' })
        }
      }
      break
    case 401: // Unauthorized request
      App.$store.dispatch('auth/resetAuth').then(() => {
        if (App.$router.history.current.name !== 'TheLogin') {
          App.$router.push({ name: 'TheLogin' }).catch((err) => {
            // eslint-disable-next-line no-console
            console.log('error - ', err)
          })
        }
      }
      )
      break
    case 503:
      if (Object.prototype.hasOwnProperty.call(error.response.data, 'message')) {
        App.$buefy.notification.open({ type: 'is-danger', message: error.response.data.message })
      } else {
        App.$buefy.notification.open({ type: 'is-danger', message: 'Pokušajte kasnije.' })
      }
      break
    default:
  }
  return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = response => {
  // TODO: Fix all notifications
  const stateChangeMethods = ['post', 'put', 'patch', 'delete']
  const ignoreRoutes = ['/auth/', '/check_unique/', '/check_password/', '/subcategories/']
  // Check if URL contains ignoredRoute
  let isIgnored = ignoreRoutes.some(function (route) {
    return this[0].includes(route)
  }, [response.config.url])
  switch (response.status) {
    case 200:
    case 201:
    case 202:
      if (stateChangeMethods.includes(response.config.method) && !isIgnored) {
        if (Object.prototype.hasOwnProperty.call(response.data, 'message')) {
          App.$buefy.notification.open({ type: 'is-success', message: response.data.message })
        } else {
          App.$buefy.notification.open({ type: 'is-success', message: 'Uspješno sačuvano' })
        }
      }
      break
    case 204:
      App.$buefy.notification.open({ type: 'is-success', message: 'Uspješno obrisano.' })
      break
    default:
      App.$buefy.notification.open({ type: 'is-success', message: 'Uspješno.' })
  }
  return response
}
httpClient.interceptors.request.use(requestAuthInterceptor)
httpClient.interceptors.response.use(responseInterceptor, responseErrorInterceptor)
const cancelTokenSource = () => axios.CancelToken.source()
export {
  httpClient,
  cancelTokenSource,
  baseUrl
}
