import { httpClient } from '@api/client'

// Auth Endpoints
const AUTH_APP = '/auth/'
const FORGOT_PASSWORD = '/settings/forgot-password/'
const CHANGE_PASSWORD = '/settings/change-password/'

export const getAuthToken = async (username, password) => {
  try {
    const { data } = await httpClient.post(AUTH_APP, { username, password })
    return data || null
  } catch (error) {
    return null
  }
}

export const deleteAuthToken = async () => {
  try {
    const { data } = await httpClient.delete(AUTH_APP)
    return data || null
  } catch (error) {
    return null
  }
}

export const postForgotPassword = async (email) => {
  try {
    const { data } = await httpClient.post(FORGOT_PASSWORD, { email })
    return data || null
  } catch (error) {
    return null
  }
}

export const patchPassword = async (password) => {
  try {
    const { data } = await httpClient.put(CHANGE_PASSWORD, { password })
    return data || null
  } catch (error) {
    return null
  }
}
