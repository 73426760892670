import { httpClient } from '@api/client'

// Words Endpoints
const DIALECT_APP = '/dialects/'

export const getDialectsWords = async (query) => {
  try {
    const { data } = await httpClient.get(`${DIALECT_APP}?${query}`)
    return data || null
  } catch (error) {
    return null
  }
}

export const postDialectWord = async (wordCnr, wordRs) => {
  const postData = {
    word_cnr: wordCnr,
    word_rs: wordRs
  }
  console.log(postData)
  try {
    const { data } = await httpClient.post(`${DIALECT_APP}`, postData)
    console.log(data)
    return data || null
  } catch (error) {
    return null
  }
}

export const deleteDialectWord = async (id) => {
  try {
    await httpClient.delete(`${DIALECT_APP}${id}/`)
    return true
  } catch (error) {
    return null
  }
}

export const patchDialectWord = async (id, wordCnr, wordRs) => {
  try {
    const { data } = await httpClient.patch(`${DIALECT_APP}` + `${id}/`, { word_cnr: wordCnr, word_rs: wordRs })
    return data || null
  } catch (error) {
    return null
  }
}
